import axios from 'axios';
import {
  DevicePacketFilter,
  DevicePacketOrder,
  DevicePackets,
  OrderPaymentType,
  OrderStatus,
  OrderSummary,
  Packet,
  PacketFilter,
  PacketType,
  RedirectUrl,
  ShopOrderPreTransaction,
  User,
} from 'common-types';
import { buildQueryString, QueryString } from 'src/utils/query-string';

const URL = '/api';

const lang = 'pl';

const getAxiosInstance = (headers: Record<string, string>, url?: string) =>
  axios.create({
    baseURL: url || URL,
    timeout: 30000,
    responseType: 'json',
    headers,
  });

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem('accessToken');

    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const axiosInstance = getAxiosInstance({
  'Content-Type': 'application/json',
  'Accept-Language': lang,
});

export async function getUser(): Promise<User> {
  return axiosInstance.get('user').then((r) => r.data);
}

function addQueryStringAndGetPath(
  path: string,
  packetId?: string,
  packetType?: PacketType,
  orderPaymentType?: OrderPaymentType
): string {
  const qs: QueryString = {};
  if (packetId) {
    qs.packetId = packetId;
  } else if (packetType) {
    qs.packetType = packetType;
  }
  if (orderPaymentType) {
    qs.orderPaymentType = orderPaymentType;
  }
  const queryString = buildQueryString(qs);
  if (queryString.length > 0) {
    path += `?${queryString}`;
  }
  return path;
}

export async function getDevicePacketsAndSession(
  filter: DevicePacketFilter
): Promise<DevicePackets> {
  const path = `order/device-packet/${filter.device.type}/${filter.device.deviceId}`;
  return axiosInstance
    .get(
      addQueryStringAndGetPath(path, filter.packetId, filter.packetType, filter.orderPaymentType)
    )
    .then((r) => r.data);
}

export async function getMemberPacketsAndSession(filter: PacketFilter): Promise<Packet[]> {
  const path = `order/member-packet`;
  return axiosInstance
    .get(addQueryStringAndGetPath(path, filter.packetId, undefined, filter.orderPaymentType))
    .then((r) => r.data);
}

export async function orderPacketAndRedirect(
  devicePacketOrder: DevicePacketOrder
): Promise<RedirectUrl> {
  return axiosInstance.post('order/packet', devicePacketOrder).then((r) => r.data);
}

export async function getOrderStatus(order: string): Promise<OrderStatus> {
  return axiosInstance.get(`order/status/${order}`).then((r) => r.data);
}

export async function getShopOrderSummaryForChangeCard(hash: string): Promise<OrderSummary> {
  return axiosInstance.post(`shop-order/${hash}`).then((r) => r.data);
}

export async function acceptShopOrderAndRedirect(
  hash: string,
  shopOrderPreTransaction: ShopOrderPreTransaction
): Promise<RedirectUrl> {
  return axiosInstance
    .post(`shop-order/${hash}/accept`, shopOrderPreTransaction)
    .then((r) => r.data);
}
export default axiosInstance;

import { Navigate, useRoutes } from 'react-router-dom';
import Redirect from 'src/routes/sections/redirect';
import { cardRoutes } from './card';

import { mainRoutes } from './main';
import { packetsRoutes } from './packets';
import { paymentsRoutes } from './payments';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Redirect />,
    },

    // Auth routes
    // ...authRoutes,

    // Dashboard routes
    // ...dashboardRoutes,

    // Packet routes
    ...packetsRoutes,

    // Payment routes
    ...paymentsRoutes,

    // Card routes
    ...cardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

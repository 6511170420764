import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { DevicePacketOrder, ErrorResponse } from 'common-types';
import * as apiClient from 'src/api/api-client';
import { getErrorResponse } from 'src/redux/common';

export const orderPacketAndRedirect = createAsyncThunk<
  void,
  DevicePacketOrder,
  { rejectValue: ErrorResponse }
>('order/orderPacket', async (order, { rejectWithValue }) => {
  apiClient
    .orderPacketAndRedirect(order)
    .then((data) => {
      window.location.replace(data.redirectUrl);
    })
    .catch((err: AxiosError<ErrorResponse>) => rejectWithValue(getErrorResponse(err)));
});

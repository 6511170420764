import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SessionGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import PacketLayout from 'src/layouts/packet';

const DevicePacketsPage = lazy(() => import('src/pages/packets/device-packets'));
const MemberPacketsPage = lazy(() => import('src/pages/packets/member-packets'));
const UserDataPage = lazy(() => import('src/pages/packets/user-data'));
const PaymentPage = lazy(() => import('src/pages/packets/payment'));

export const packetsRoutes = [
  {
    path: 'packets',
    element: (
      <SessionGuard>
        <PacketLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PacketLayout>
      </SessionGuard>
    ),
    children: [
      { element: <MemberPacketsPage />, index: true },
      { path: 'data', element: <UserDataPage /> },
      { path: 'pay', element: <PaymentPage /> },
      { path: ':type/:deviceId', element: <DevicePacketsPage /> },
      { path: ':type/:deviceId/data', element: <UserDataPage /> },
      { path: ':type/:deviceId/pay', element: <PaymentPage /> },
    ],
  },
];

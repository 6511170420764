// ----------------------------------------------------------------------

import { PaymentStatus } from 'common-types';
import { isAndroid, isIOS } from 'src/utils/mobile';

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  PACKETS: '/packets',
  PAYMENTS: '/payments',
  CARD: '/card',
};

// ----------------------------------------------------------------------

export function leaveApp(cardChange: boolean, paymentStatus: PaymentStatus) {
  if (!cardChange && isAndroid()) {
    switch (paymentStatus) {
      case PaymentStatus.SUCCESS:
        window.MobileAppInterface.success();
        break;
      case PaymentStatus.PENDING:
        window.MobileAppInterface.pending();
        break;
      case PaymentStatus.FAILURE:
        window.MobileAppInterface.failure();
        break;
      default:
    }
  } else if (!cardChange && isIOS()) {
    window.webkit.messageHandlers.iosClickListener.postMessage('next');
  } else {
    window.location.assign(process.env.REACT_APP_URL_PACKAGES || 'https://bezpiecznarodzina.pl/');
  }
}

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // PACKETS:
  packets: {
    root: ROOTS.PACKETS,
    memberData: `${ROOTS.PACKETS}/data`,
    memberPay: `${ROOTS.PACKETS}/pay`,
    device: (type: string, deviceId: string) => `${ROOTS.PACKETS}/${type}/${deviceId}`,
    deviceData: (type: string, deviceId: string) => `${ROOTS.PACKETS}/${type}/${deviceId}/data`,
    devicePay: (type: string, deviceId: string) => `${ROOTS.PACKETS}/${type}/${deviceId}/pay`,
  },
  // PAYMENTS:
  payments: {
    root: ROOTS.PAYMENTS,
    return: (hash: string) => `${ROOTS.PAYMENTS}/return/${hash}`,
  },
  // CARD:
  card: {
    root: ROOTS.CARD,
    info: (hash: string) => `${ROOTS.CARD}/${hash}`,
    change: (hash: string) => `${ROOTS.CARD}/${hash}/change`,
  },
  // DASHBOARD - deprecated
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
};

export type QueryString = Record<string, string>;

export function parseQueryString(qs: string): QueryString {
  if (!qs) return {} as QueryString;

  if (qs[0] === '?') {
    qs = qs.substr(1);
  }

  return qs
    .split('&')
    .map((e) => e.split('='))
    .reduce((params, [name, value]) => {
      if (name.length === 0) return params;

      params[name] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {} as QueryString);
}

export function buildQueryString(qs: QueryString): string {
  if (!qs) return '';

  return Object.keys(qs)
    .map((k) => `${k}=${encodeURIComponent(qs[k])}`)
    .join('&');
}

export const parsed = parseQueryString(window.location.search);

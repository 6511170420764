import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Device,
  DevicePacketOrder,
  ExtraState,
  OrderPaymentType,
  PaymentType,
  UserData,
} from 'common-types';
import { orderPacketAndRedirect } from 'src/redux/order/action';

interface OrderInitialState extends DevicePacketOrder, ExtraState {}

const orderInitialState: Partial<OrderInitialState> = {
  paymentType: PaymentType.CARD,
  loading: false,
};

const orderSlice = createSlice({
  name: 'order',
  initialState: orderInitialState as OrderInitialState,
  reducers: {
    setDevice: (state, action: PayloadAction<Device>) => {
      state.device = action.payload;
    },
    setPacket: (state, action: PayloadAction<Pick<DevicePacketOrder, 'packet' | 'packetFor'>>) => {
      state.packet = action.payload.packet;
      state.packetFor = action.payload.packetFor;
      if (action.payload.packet.orderPaymentType === OrderPaymentType.RECURRING) {
        state.paymentType = PaymentType.RECURRING;
      } else if (
        action.payload.packet.orderPaymentType === OrderPaymentType.ONE_TIME &&
        state.paymentType === PaymentType.RECURRING
      ) {
        state.paymentType = PaymentType.CARD;
      }
    },
    setPaymentType: (state, action: PayloadAction<PaymentType>) => {
      state.paymentType = action.payload;
    },
    initializeUserData: (state, action: PayloadAction<UserData>) => {
      if (!state.orderClient) {
        state.orderClient = action.payload;
      }
    },
    setUserData: (
      state,
      action: PayloadAction<Pick<DevicePacketOrder, 'orderClient' | 'invoice'>>
    ) => {
      state.orderClient = action.payload.orderClient;
      state.invoice = action.payload.invoice;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(orderPacketAndRedirect.pending, (state: OrderInitialState) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(orderPacketAndRedirect.fulfilled, (state: OrderInitialState) => {
      state.loading = true;
    });
    builder.addCase(orderPacketAndRedirect.rejected, (state: OrderInitialState, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { setDevice, setPacket, setPaymentType, initializeUserData, setUserData } =
  orderSlice.actions;

export default orderSlice.reducer;

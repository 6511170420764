import { createSlice } from '@reduxjs/toolkit';
import { ExtraState, OrderStatus } from 'common-types';
import { getOrderStatus, refreshOrderStatus } from 'src/redux/order-status/action';

interface OrderStatusState extends ExtraState {
  status: OrderStatus;
}

const orderStatusSlice = createSlice({
  name: 'orderStatus',
  initialState: {
    loading: false,
  } as OrderStatusState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrderStatus.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getOrderStatus.fulfilled, (state, action) => {
      state.status = action.payload;
      state.loading = false;
      state.error = undefined;
    });
    builder.addCase(getOrderStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(refreshOrderStatus.fulfilled, (state, action) => {
      state.status = action.payload;
      state.error = undefined;
    });
  },
});

export default orderStatusSlice.reducer;

import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import CardLayout from 'src/layouts/card';

const CardInfoPage = lazy(() => import('src/pages/card/card-info'));
const CardChangePage = lazy(() => import('src/pages/card/card-change'));

export const cardRoutes = [
  {
    path: 'card',
    element: (
      <CardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </CardLayout>
    ),
    children: [
      { element: <Navigate to="/404" replace />, index: true },
      { path: ':hash', element: <CardInfoPage /> },
      { path: ':hash/change', element: <CardChangePage /> },
    ],
  },
];

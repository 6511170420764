import { useCallback, useEffect, useState } from 'react';
import { useSessionContext } from 'src/auth/hooks/use-session-context';
import { SplashScreen } from 'src/components/loading-screen';
import { useRouter } from 'src/routes/hooks';

type Props = {
  children: React.ReactNode;
};

export default function SessionGuard({ children }: Props) {
  const { loading } = useSessionContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();

  const { authenticated } = useSessionContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const href = `/`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

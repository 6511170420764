/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';
import { SessionProvider } from 'src/auth/context/session';
import { MotionLazy } from 'src/components/animate/motion-lazy';

import ProgressBar from 'src/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { LocalizationProvider } from 'src/locales';
import 'src/locales/i18n';
// ----------------------------------------------------------------------
import Router from 'src/routes/sections';

import ThemeProvider from 'src/theme';

// ----------------------------------------------------------------------

export default function App() {
  const charAt = `

  ░░        ░░░░░░    ░░░░░░   ░░░░░░   ░░░   ░░
  ▒▒       ▒▒    ▒▒  ▒▒       ▒▒    ▒▒  ▒▒▒▒  ▒▒
  ▒▒       ▒▒    ▒▒  ▒▒       ▒▒ ▒▒ ▒▒  ▒▒ ▒▒ ▒▒
  ▓▓       ▓▓    ▓▓  ▓▓       ▓▓    ▓▓  ▓▓  ▓▓▓▓
  ███████   ██████    ██████   ██████   ██   ███

  `;

  console.info(`%c${charAt}`, 'color: #3366FF');

  useScrollToTop();

  return (
    <SessionProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SettingsDrawer />
              <ProgressBar />
              <Router />
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </SessionProvider>
  );
}

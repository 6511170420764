import { createEntityAdapter, createSlice, EntityId } from '@reduxjs/toolkit';
import { ExtraState, Packet } from 'common-types';
import { getDevicePackets, getMemberPackets } from 'src/redux/packets/action';

export const packetsAdapter = createEntityAdapter<Packet, EntityId>({
  selectId: (packet) => packet.id,
  sortComparer: (a, b) => b.orderPaymentType.localeCompare(a.orderPaymentType) || b.price - a.price,
});

const packetsSlice = createSlice({
  name: 'packets',
  initialState: packetsAdapter.getInitialState({
    loading: false,
  } as ExtraState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDevicePackets.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getDevicePackets.fulfilled, (state, action) => {
      packetsAdapter.setAll(state, action.payload.packets);
      state.loading = false;
    });
    builder.addCase(getDevicePackets.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getMemberPackets.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getMemberPackets.fulfilled, (state, action) => {
      packetsAdapter.setAll(state, action.payload);
      state.loading = false;
    });
    builder.addCase(getMemberPackets.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default packetsSlice.reducer;

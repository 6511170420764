import { Navigate } from 'react-router-dom';
import useQuery from 'src/hooks/use-query';
import { paths } from 'src/routes/paths';

export default function Redirect() {
  const query = useQuery();
  if (query.has('order')) {
    return <Navigate to={paths.payments.return(`${query.get('order')}`)} replace />;
  }

  return <Navigate to="/404" replace />;
}

import { createSlice } from '@reduxjs/toolkit';
import { ExtraState, OrderSummary } from 'common-types';
import {
  acceptShopOrderAndRedirect,
  getOrderSummaryForCardChange,
} from 'src/redux/order-summary/action';

interface OrderSummaryState extends ExtraState {
  summary: OrderSummary;
  isSubmitting: boolean;
}

const orderSummarySlice = createSlice({
  name: 'orderSummary',
  initialState: {
    loading: true,
    isSubmitting: false,
  } as OrderSummaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrderSummaryForCardChange.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getOrderSummaryForCardChange.fulfilled, (state, action) => {
      state.summary = action.payload;
      state.loading = false;
      state.error = undefined;
    });
    builder.addCase(getOrderSummaryForCardChange.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(acceptShopOrderAndRedirect.pending, (state) => {
      state.isSubmitting = true;
      state.error = undefined;
    });
    builder.addCase(acceptShopOrderAndRedirect.fulfilled, (state) => {
      state.isSubmitting = true;
      state.error = undefined;
    });
    builder.addCase(acceptShopOrderAndRedirect.rejected, (state, action) => {
      state.isSubmitting = false;
      state.error = action.payload;
    });
  },
});

export default orderSummarySlice.reducer;

import merge from 'lodash/merge';
// date fns
import { pl as plAdapter, enUS as enUSAdapter } from 'date-fns/locale';

// date pickers (MUI)
import { enUS as enUSDate, plPL as plPLDate } from '@mui/x-date-pickers/locales';
// core (MUI)
import { enUS as enUSCore, plPL as plPLCore } from '@mui/material/locale';
// data grid (MUI)
// import {
//   enUS as enUSDataGrid,
// } from '@mui/x-data-grid';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Polski',
    value: 'pl',
    systemValue: merge(plPLDate, plPLCore),
    adapterLocale: plAdapter,
    icon: 'flagpack:pl',
    numberFormat: {
      code: 'pl',
      currency: 'PLN',
    },
  },
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
    numberFormat: {
      code: 'en-US',
      currency: 'USD',
    },
  },
];

export const defaultLang = allLangs[0]; // Polish

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0

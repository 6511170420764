import { useContext } from 'react';

import { SessionContext } from '../context/session';

// ----------------------------------------------------------------------

export const useSessionContext = () => {
  const context = useContext(SessionContext);

  if (!context) throw new Error('useContextContext context must be use inside SessionProvider');

  return context;
};

import Box from '@mui/material/Box';
import Main from 'src/layouts/common/main';

type Props = {
  children: React.ReactNode;
};

export default function PacketLayout({ children }: Props) {
  return (
    <>
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Main>{children}</Main>
      </Box>
    </>
  );
}

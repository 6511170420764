import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import PacketLayout from 'src/layouts/packet';

const PaymentsStatusPage = lazy(() => import('src/pages/payments/payment-status'));

export const paymentsRoutes = [
  {
    path: '/payments',
    element: (
      <PacketLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </PacketLayout>
    ),
    children: [
      { element: <Navigate to="/404" replace />, index: true },
      { path: 'return/:order', element: <PaymentsStatusPage /> },
    ],
  },
];

import { AxiosError } from 'axios';
import { ErrorResponse } from 'common-types';

export const getErrorResponse = (error: AxiosError<ErrorResponse>): ErrorResponse => {
  if (error.response) {
    if (error.response.status === 401) {
      return { code: 'UNAUTHORIZED', message: 'Unauthorized' };
    }
    if (error.response.status === 404) {
      if (error.response.config.url === '/user/login') {
        return { code: 'error.incorrectDataLogin', message: 'Wrong data' };
      }
      return { code: 'ERR_BAD_REQUEST', message: 'Bad request' };
    }
    if (error.response.data) {
      return {
        code:
          // eslint-disable-next-line no-nested-ternary
          error.response.data.code === ''
            ? error.code
              ? error.code
              : ''
            : error.response.data.code,
        message: error.response.data.message,
      };
    }
    return { code: error.response.statusText, message: error.response.statusText };
  }
  if (error.request) {
    return { code: 'NO_RESPONSE_FROM_SERVER', message: 'No response from server' };
  }
  return { code: 'INTERNAL_SERVER_ERROR', message: error.message };
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorResponse, OrderStatus } from 'common-types';
import * as apiClient from 'src/api/api-client';
import { getErrorResponse } from 'src/redux/common';

export const getOrderStatus = createAsyncThunk<OrderStatus, string, { rejectValue: ErrorResponse }>(
  'orderStatus/get',
  async (order: string, { rejectWithValue }) =>
    apiClient
      .getOrderStatus(order)
      .catch((err: AxiosError<ErrorResponse>) => rejectWithValue(getErrorResponse(err)))
);

export const refreshOrderStatus = createAsyncThunk<
  OrderStatus,
  string,
  { rejectValue: ErrorResponse }
>('orderStatus/refresh', async (order: string, { rejectWithValue }) =>
  apiClient
    .getOrderStatus(order)
    .catch((err: AxiosError<ErrorResponse>) => rejectWithValue(getErrorResponse(err)))
);

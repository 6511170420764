import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorResponse, OrderSummary, ShopOrderPreTransaction } from 'common-types';
import * as apiClient from 'src/api/api-client';
import { getErrorResponse } from 'src/redux/common';

export const getOrderSummaryForCardChange = createAsyncThunk<
  OrderSummary,
  string,
  { rejectValue: ErrorResponse }
>('orderSummary/get', async (hash: string, { rejectWithValue }) =>
  apiClient
    .getShopOrderSummaryForChangeCard(hash)
    .catch((err: AxiosError<ErrorResponse>) => rejectWithValue(getErrorResponse(err)))
);

export const acceptShopOrderAndRedirect = createAsyncThunk<
  void,
  { hash: string; shopOrderPreTransaction: ShopOrderPreTransaction },
  { rejectValue: ErrorResponse }
>('orderSummary/accept', async (arg, { rejectWithValue }) =>
  apiClient
    .acceptShopOrderAndRedirect(arg.hash, arg.shopOrderPreTransaction)
    .then((data) => {
      window.location.replace(data.redirectUrl);
    })
    .catch((err: AxiosError<ErrorResponse>) => rejectWithValue(getErrorResponse(err)))
);

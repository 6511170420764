import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import Main from 'src/layouts/common/main';
import { getOrderSummaryForCardChange } from 'src/redux/order-summary';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { useParams, useRouter } from 'src/routes/hooks';

type Props = {
  children: React.ReactNode;
};

export default function CardLayout({ children }: Props) {
  const { hash } = useParams();
  const router = useRouter();
  const dispatch = useDispatch();

  const { loading, orderSummary } = useSelector(
    (state: RootState) => ({
      loading: state.orderSummary.loading,
      orderSummary: state.orderSummary.summary,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (hash) {
      dispatch(getOrderSummaryForCardChange(hash));
    }
  }, [hash, dispatch]);

  useEffect(() => {
    if (!loading && !orderSummary) {
      router.push('/404');
    }
  }, [loading, orderSummary, router]);

  return (
    <>
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Main>{children}</Main>
      </Box>
    </>
  );
}

// Zcacheowana wartość isMobile
const isMobileAndroid = testIsAndroid();
const isMobileIOS = testIsIOS();
const isMobileNow = testIsMobile();

function testIsAndroid(): boolean {
  return /Android/i.test(navigator.userAgent);
}

function testIsIOS(): boolean {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

function testIsMobile(): boolean {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth < 800;
}

export function isAndroid(): boolean {
  return isMobileAndroid;
}

export function isIOS(): boolean {
  return isMobileIOS;
}

export function isMobile(): boolean {
  return isMobileNow;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PacketFor = exports.PersonalTrackerModel = exports.InitialPaymentType = exports.OrderPaymentType = exports.TrackerColor = exports.OrderDiscriminator = exports.PaymentStatus = exports.DeviceType = exports.PaymentType = exports.PacketType = exports.ServiceType = exports.ClientType = exports.Network = void 0;
var Network;
(function (Network) {
    Network["TMOBILE"] = "ERA";
    Network["ORANGE"] = "ORANGE";
    Network["PLAY"] = "PLAY";
    Network["PLUS"] = "PLUS";
})(Network || (exports.Network = Network = {}));
var ClientType;
(function (ClientType) {
    ClientType["WEB"] = "WEB";
    ClientType["ANDROID"] = "ANDROID";
    ClientType["IOS"] = "IOS";
})(ClientType || (exports.ClientType = ClientType = {}));
var ServiceType;
(function (ServiceType) {
    ServiceType["GJD_BR"] = "GJD_BR";
    ServiceType["BD"] = "BD";
    ServiceType["BA"] = "BA";
    ServiceType["GJP"] = "GJP";
    ServiceType["BF"] = "BF";
    ServiceType["BDR"] = "BDR";
    ServiceType["BS"] = "BS";
    ServiceType["BZ"] = "BZ";
    ServiceType["BR_OS"] = "BR_OS";
    ServiceType["SOS"] = "SOS";
    ServiceType["GJD_SOS"] = "GJD_SOS";
    ServiceType["BR_ZWD"] = "BR_ZWD";
    ServiceType["BR_JWD"] = "BR_JWD";
})(ServiceType || (exports.ServiceType = ServiceType = {}));
var PacketType;
(function (PacketType) {
    PacketType["PLATINUM"] = "PLATINUM";
    PacketType["GOLD"] = "GOLD";
    PacketType["SILVER"] = "SILVER";
    PacketType["BRONZE"] = "BRONZE";
})(PacketType || (exports.PacketType = PacketType = {}));
var PaymentType;
(function (PaymentType) {
    PaymentType["CARD"] = "CARD";
    PaymentType["QUICK_TRANSFER"] = "QUICK_TRANSFER";
    PaymentType["RECURRING"] = "RECURRING";
})(PaymentType || (exports.PaymentType = PaymentType = {}));
var DeviceType;
(function (DeviceType) {
    DeviceType["PGPS"] = "PGPS";
    DeviceType["CAMERA"] = "CAMERA";
    DeviceType["GPS"] = "GPS";
    DeviceType["MEMBER"] = "MEMBER";
})(DeviceType || (exports.DeviceType = DeviceType = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["PENDING"] = "PENDING";
    PaymentStatus["SUCCESS"] = "SUCCESS";
    PaymentStatus["FAILURE"] = "FAILURE";
})(PaymentStatus || (exports.PaymentStatus = PaymentStatus = {}));
var OrderDiscriminator;
(function (OrderDiscriminator) {
    OrderDiscriminator["DEVICE_PACKET"] = "DEVICE_PACKET";
    OrderDiscriminator["SHOP"] = "SHOP";
})(OrderDiscriminator || (exports.OrderDiscriminator = OrderDiscriminator = {}));
var TrackerColor;
(function (TrackerColor) {
    TrackerColor["BLACK"] = "BLACK";
    TrackerColor["BLUE"] = "BLUE";
    TrackerColor["GREEN"] = "GREEN";
    TrackerColor["PINK"] = "PINK";
    TrackerColor["ORANGE"] = "ORANGE";
    TrackerColor["WHITE"] = "WHITE";
    TrackerColor["BROWN"] = "BROWN";
    TrackerColor["YELLOW"] = "YELLOW";
    TrackerColor["RED"] = "RED";
    TrackerColor["NAVY"] = "NAVY";
})(TrackerColor || (exports.TrackerColor = TrackerColor = {}));
var OrderPaymentType;
(function (OrderPaymentType) {
    OrderPaymentType["ONE_TIME"] = "ONE_TIME";
    OrderPaymentType["RECURRING"] = "RECURRING";
})(OrderPaymentType || (exports.OrderPaymentType = OrderPaymentType = {}));
var InitialPaymentType;
(function (InitialPaymentType) {
    InitialPaymentType["INIT_WITH_PAYMENT"] = "INIT_WITH_PAYMENT";
    InitialPaymentType["INIT_WITH_REFUND"] = "INIT_WITH_REFUND";
})(InitialPaymentType || (exports.InitialPaymentType = InitialPaymentType = {}));
var PersonalTrackerModel;
(function (PersonalTrackerModel) {
    PersonalTrackerModel["HUABAO_HB_T10"] = "HUABAO_HB_T10";
    PersonalTrackerModel["LEADWAY_TKW_19R"] = "LEADWAY_TKW_19R";
    PersonalTrackerModel["TOMO_ASTRO_110"] = "TOMO_ASTRO_110";
    PersonalTrackerModel["LEADWAY_TKP_19GIII"] = "LEADWAY_TKP_19GIII";
    PersonalTrackerModel["CESSBO_SH_991"] = "CESSBO_SH_991";
    PersonalTrackerModel["GREAT_WILL_TK88"] = "GREAT_WILL_TK88";
    PersonalTrackerModel["GREAT_WILL_TK89"] = "GREAT_WILL_TK89";
    PersonalTrackerModel["TOMO_ASTRO_120"] = "TOMO_ASTRO_120";
    PersonalTrackerModel["SENTAR_Q60"] = "SENTAR_Q60";
    PersonalTrackerModel["ESINO_ES_GP31"] = "ESINO_ES_GP31";
    PersonalTrackerModel["ESINO_ES_GP32"] = "ESINO_ES_GP32";
    PersonalTrackerModel["_4P_TOUCH_D25S"] = "_4P_TOUCH_D25S";
    PersonalTrackerModel["KINGWEAR_KW88"] = "KINGWEAR_KW88";
    PersonalTrackerModel["KINGWEAR_KW98"] = "KINGWEAR_KW98";
    PersonalTrackerModel["CESSBO_SH_993"] = "CESSBO_SH_993";
    PersonalTrackerModel["PLOYER_D59"] = "PLOYER_D59";
    PersonalTrackerModel["MOTTO_G900S"] = "MOTTO_G900S";
    PersonalTrackerModel["APPSCOMM_H93"] = "APPSCOMM_H93";
    PersonalTrackerModel["MOTTO_LT08"] = "MOTTO_LT08";
    PersonalTrackerModel["PT_H67"] = "PT_H67";
    PersonalTrackerModel["PETGRAM"] = "PETGRAM";
})(PersonalTrackerModel || (exports.PersonalTrackerModel = PersonalTrackerModel = {}));
var PacketFor;
(function (PacketFor) {
    PacketFor["MEMBER"] = "MEMBER";
    PacketFor["DEVICE"] = "DEVICE";
})(PacketFor || (exports.PacketFor = PacketFor = {}));

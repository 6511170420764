import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUser, setSession } from 'src/api/api-client';
import { SessionContext } from 'src/auth/context/session/session-context';
import { ActionMapType, AuthStateType, AuthUserType } from 'src/auth/types';
import useQuery from 'src/hooks/use-query';
import { initializeUserData } from 'src/redux/order/reducer';
import { useDispatch } from 'src/redux/store';

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};
const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
type Props = {
  children: React.ReactNode;
};

export function SessionProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const query = useQuery();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const initialize = useCallback(async () => {
    try {
      const sid = query.get('session') || sessionStorage.getItem(STORAGE_KEY);
      if (query.has('session')) {
        query.delete('session');
        navigate({ pathname, search: query.toString() });
      }

      if (sid) {
        setSession(sid);
        // FIXME: replace with redux toolkit action and reducer
        const user = await getUser();
        reduxDispatch(
          initializeUserData({
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
          })
        );

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      setSession(null);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, [query, navigate, pathname, reduxDispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'sso',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
    }),
    [state.user, status]
  );

  return <SessionContext.Provider value={memoizedValue}>{children}</SessionContext.Provider>;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  DevicePacketFilter,
  DevicePackets,
  ErrorResponse,
  Packet,
  PacketFilter,
} from 'common-types';
import * as apiClient from 'src/api/api-client';
import { getErrorResponse } from 'src/redux/common';

export const getDevicePackets = createAsyncThunk<
  DevicePackets,
  DevicePacketFilter,
  { rejectValue: ErrorResponse }
>('packets/getDevicePackets', async (filter: DevicePacketFilter, { rejectWithValue }) =>
  apiClient
    .getDevicePacketsAndSession(filter)
    .catch((err: AxiosError<ErrorResponse>) => rejectWithValue(getErrorResponse(err)))
);

export const getMemberPackets = createAsyncThunk<
  Packet[],
  PacketFilter,
  { rejectValue: ErrorResponse }
>('packets/getMemberPackets', async (filter, { rejectWithValue }) =>
  apiClient
    .getMemberPacketsAndSession(filter)
    .catch((err: AxiosError<ErrorResponse>) => rejectWithValue(getErrorResponse(err)))
);
